import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  img: {
    width: 180,
    margin: theme.spacing(2, 0)
  },
  text: {
    fontWeight: 'bold'
  }
}))

const Loading = () => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <img className={classes.img} src={'load.png'} />
      <Typography className={classes.text} variant={'body1'} color={'primary'} align={'center'}>
        로딩중...
      </Typography>
    </Box>
  )
}

export default Loading;
import React from 'react'
import { Box, Container, makeStyles } from '@material-ui/core'

import Header from '../../components/Header';
import CloseBar from './components/CloseBar';

const useStyles = makeStyles(theme => ({
  layout: {
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    flexGrow: 1,
    flexShrink: 0,
    backgroundColor: theme.palette.background.inner
  }
}))

const CloseLayout = ({ children, ...props }) => {
  const classes = useStyles()
  return (
    <Box>
      <Header {...props}/>
      <CloseBar {...props} />
      <Container className={classes.container} maxWidth={'lg'}>
        {children}
      </Container>
    </Box>
  )
}

export default CloseLayout;
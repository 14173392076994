import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';

import User from 'models/User';
import Customer from 'models/Customer';
import { authApi, bgoodApi } from 'utils/axios';
import Loading from 'components/Loading';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  customer: null,
  temp: false
};

const isValidToken = token => {
  if (!token) return false;
  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

const setSession = token => {
  if (token) {
    if (!token.startsWith('Bearer')) {
      token = `Bearer ${token}`;
    }
    localStorage.setItem('authorization', token);
    bgoodApi.defaults.headers.common.Authorization = token;
    authApi.defaults.headers.common.Authorization = token;
  } else {
    localStorage.removeItem('authorization');
    delete bgoodApi.defaults.headers.common.Authorization;
    delete authApi.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isInitialised, isAuthenticated, user, customer, temp } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised,
        user: user ? new User(user) : null,
        customer: customer ? new Customer(customer) : null,
        temp
      };
    }
    case 'LOGIN': {
      const { user, temp } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user,
        temp
      };
    }
    case 'LOGOUT': {
      return {
        isInitialised: false,
        isAuthenticated: false,
        user: null,
        customer: null,
        temp: false
      };
    }
    case 'PASSWORD_RESET': {
      const { temp } = action.payload;
      return {
        ...state,
        isAuthenticated: false,
        temp
      };
    }
    case 'PASSWORD_CHANGE': {
      const { temp } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        temp
      };
    }
    default:
      return {
        ...state
      };
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => {
  },
  passwordReset: () => Promise.resolve(),
  passwordChange: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const verify = async () => {
    try {
      const authorization = window.localStorage.getItem('authorization');
      if (authorization && isValidToken(authorization)) {
        setSession(authorization);
        const response = await bgoodApi.get('/v1/app/customer');
        const { user, customer } = response.data.result;
        dispatch({
          type: 'INITIALISE',
          payload: {
            isInitialised: true,
            isAuthenticated: true,
            user,
            customer,
            temp: user ? user.isTemp : false
          }
        });

      } else {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isInitialised: true,
            isAuthenticated: false,
            user: null,
            customer: null
          }
        });
      }
    } catch (e) {
      dispatch({
        type: 'INITIALISE',
        payload: {
          isInitialised: true,
          isAuthenticated: false,
          user: null,
          customer: null
        }
      });
    }
  };

  const login = async (registerNumber, password) => {
    const response = await bgoodApi.post('/v1/login', { registerNumber, password });
    const { accessToken, user } = response.data;
    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
    await verify();
  };

  const logout = () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
      isAuthenticated: false,
      user: null,
      customer: null
    });
  };

  const passwordReset = async (registerNumber) => {
    const response = await authApi.put('/v1/password/reset', { registerNumber });
    if (response.status === 200) {
      dispatch({
        type: 'PASSWORD_RESET',
        payload: {
          temp: true
        }
      })
    }
    return response.data;
  };

  const passwordChange = async (password, passwordConfirm) => {
    const response = await authApi.put('/v1/password/change', { password, passwordConfirm });
    if (response.status === 200) {
      dispatch({
        type: 'PASSWORD_CHANGE',
        payload: {
          temp: false
        }
      })
    }
    return response.data;
  };

  useEffect(() => {
    verify().catch(console.error);
  }, [state?.user?.id]);

  if (!state.isInitialised) {
    return <Loading/>;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        passwordReset,
        passwordChange
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
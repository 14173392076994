import React, { lazy } from 'react'
import PlainLayout from 'layouts/PlainLayout';
import MainLayout from 'layouts/MainLayout'
import { Redirect } from 'react-router-dom';

export default [
  {
    title: 'Bgood 주문하기',
    path: '/',
    guard: true,
    exact: true,
    layout: MainLayout,
    page: lazy(() => import('../pages/bgood/OrderCreatePage'))
  },
  {
    title: 'SNE Company 로그인',
    path: '/login',
    exact: true,
    guard: false,
    layout: PlainLayout,
    page: lazy(() => import('../pages/LoginPage'))
  },
  {
    title: 'Bgood 주문하기',
    path: '/bgood/order',
    exact: true,
    guard: true,
    layout: MainLayout,
    page: lazy(() => import('../pages/bgood/OrderCreatePage'))
  },
  {
    title: 'Bgood 주문내역',
    path: '/bgood/history',
    exact: true,
    guard: true,
    layout: MainLayout,
    page: lazy(() => import('../pages/bgood/OrderListPage'))
  },
  {
    title: '비밀번호 변경',
    path: '/password',
    exact: true,
    guard: true,
    layout: PlainLayout,
    page: lazy(() => import('../pages/PasswordChangePage'))
  },
  {
    title: '404',
    path: '*',
    guard: false,
    layout: PlainLayout,
    page: () => <Redirect to={'/404'} />
  }
]
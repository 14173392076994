import React from 'react';
import Model from './lib/Model';

class User extends Model {
  constructor(raw) {
    super(raw);
    this.registerNumber = raw.registerNumber;
    this.name = raw.name;
    this.email = raw.email;
    this.tel = raw.tel;
    this.postalCode = raw.postalCode;
    this.address1 = raw.address1;
    this.address2 = raw.address2;
    this.isTemp = raw.isTemp;
    this.isIndividual = raw.isIndividual;
    this.ownerName = raw.ownerName;
  }
}
export default User;
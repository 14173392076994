import React from 'react'
import clsx from 'clsx';
import { useHistory } from 'react-router'
import { AppBar, Toolbar, makeStyles, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  logo: {
    height: 26
  },
  toolBar: {
    justifyContent: 'space-between'
  },
  close: {
    marginRight: -12
  }
}))

const CloseBar = ({ className }) => {
  const classes = useStyles()
  const history = useHistory()

  const handleClose = () => {
    history.goBack();
  }

  return (
    <AppBar
      className={clsx(className)}
      color={'inherit'}
      position={'sticky'}
      elevation={0}
    >
      <Toolbar className={classes.toolBar}>
        <img src={'logo.png'} className={classes.logo} />
        <IconButton className={classes.close} onClick={handleClose}>
          <Close />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default CloseBar;
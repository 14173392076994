import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import Loading from './components/Loading';
import NotFound from './pages/404';
import PrivateRoute from './components/PrivateRoute';

import { AuthProvider } from 'contexts/JWTAuthContext';

import routes from './routes';

function App() {
  return (
    <Suspense fallback={<Loading/>}>
      <AuthProvider>
        <SnackbarProvider>
          <Router>
            <Switch>
              {
                routes.map(({ layout, path, page, exact, guard, ...routes }) => {
                  return <PrivateRoute
                    key={path}
                    layout={layout}
                    path={path}
                    guard={guard}
                    component={page}
                    exact={exact}
                    {...routes}
                  />;
                })
              }
              <Route path={'*'} component={NotFound}/>
            </Switch>
          </Router>
        </SnackbarProvider>
      </AuthProvider>
    </Suspense>
  );
}

export default App;

import axios from 'axios';

const instance = axios.create({
  timeout: 5000,
  headers: { 'Access-Control-Allow-Origin': '*' }
})

export default instance;

export const authApi = axios.create({
  baseURL: 'https://auth.snecompany.com',
  timeout: 5000,
  headers: { 'Access-Control-Allow-Origin': '*' }
});

export const bgoodApi = axios.create({
  baseURL: 'https://api.bgood.co.kr',
  // baseURL: 'http://localhost:4000',
  timeout: 5000,
  headers: { 'Access-Control-Allow-Origin': '*' }
});

import React from 'react';
import { Redirect, Route, useLocation } from 'react-router';
import useAuth from '../hooks/useAuth';
import ErrorBoundary from './ErrorBoundary';
import ScrollToTop from './ScrollToTop';

const PrivateRoute = ({ layout: Layout, component: Component, path, guard, ...props }) => {
  const { pathname } = useLocation();
  const { isAuthenticated, customer, temp } = useAuth();

  if (guard && !(isAuthenticated && customer)) {
    return <Redirect to={'/login'}/>;
  } else if (temp && pathname !== '/password' && pathname !== '/login') {
    return <Redirect to={'/password'}/>;
  }

  const renderComponent = () => {
    return (
      <ScrollToTop>
        <Layout {...props}>
          <Component/>
        </Layout>
      </ScrollToTop>
    );
  };

  return (
    <ErrorBoundary error={<p>Page has a error</p>}>
      <Route
        exact
        path={path}
        render={renderComponent}
      />
    </ErrorBoundary>
  );
};

export default PrivateRoute;
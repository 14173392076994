import React, { useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useHistory } from 'react-router'
import { useSnackbar } from 'notistack'
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core'
import useAuth from '../../../hooks/useAuth';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 32,
    width: 32,
  },
  popover: {
    width: 200
  },
  name: {
    margin: theme.spacing(0, 2)
  }
}))

const Account = () => {
  const classes = useStyles();
  const history = useHistory()
  const ref = useRef(null);
  const { user, logout } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      history.push('/login')
    } catch (e) {
      enqueueSnackbar('로그아웃이 실패되었습니다.', { variant: 'error' })
    }
  }

  return (
    <>
      <Box
        className={classes.container}
        ref={ref}
        component={ButtonBase}
        onClick={handleOpen}
      >
        <Avatar
          alt={'User'}
          className={classes.avatar}
        />
        <Hidden smDown>
          <Typography variant={'body1'} color={'inherit'} className={classes.name}>
            {user.name}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={handleLogout}>
          로그아웃
        </MenuItem>
      </Menu>
    </>
  )
}

export default Account;
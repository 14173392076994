import { useEffect, useRef } from 'react'

function useIsMountedRef() {
  const isMouted = useRef(true)

  useEffect(() => {
    isMouted.current = false;
  }, [])

  return isMouted;
}

export default useIsMountedRef;
import React from 'react'
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom'
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon, Typography
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Account from './Account';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary
  },
  toolbar: {
    minHeight: 64
  }
}))

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles()
  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar className={classes.toolbar}>
        <IconButton color={'inherit'} onClick={onMobileNavOpen}>
          <SvgIcon fontSize={'small'}>
            <MenuIcon/>
          </SvgIcon>
        </IconButton>
        <Box ml={2} flexGrow={1} />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar;
import React, {useEffect} from 'react'
import { useLocation, matchPath } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core'

import useAuth from 'hooks/useAuth';
import Logo from 'components/Logo';
import NavItem from './NavItem';

const navConfig = [
  {
    subheader: '공동 구매',
    items: [
      {
        title: 'Bgood 상품',
        href: '/bgood/order',
        items: [
          {
            title: '주문 하기',
            href: '/bgood/order'
          },
          {
            title: '주문 내역',
            href: '/bgood/history'
          }
        ]
      },
    ]
  }
]

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }), []
      )}
    </List>
  )
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0
                           }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    })
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    )
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}))

function NavBar({ open, onClose }) {
  const classes = useStyles();
  const location = useLocation()
  const { user } = useAuth()

  useEffect(() => {
    if (open && onClose) {
      onClose();
    }
  }, [location.pathname])

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box
          p={2}
          display="flex"
          justifyContent="center"
        >
          <RouterLink to="/">
            <Logo/>
          </RouterLink>
        </Box>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                // src={user.avatar}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.name}`}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {`${user.tel}`}
            </Typography>
          </Box>
        </Box>
        <Divider/>
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  )

  return (
    <Drawer
      anchor={'left'}
      classes={{ paper: classes.mobileDrawer }}
      onClose={onClose}
      open={open}
      variant={'temporary'}
    >
      {content}
    </Drawer>
  )
}

export default NavBar;
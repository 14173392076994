import { createMuiTheme } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#173147',
      light: 'rgba(60,112,156,0.7)',
      contrastText: '#fff'
    },
    secondary: {
      main: '#00b946',
      light: 'rgb(116,207,148)',
      contrastText: '#fff'
    },
    error: {
      main: red.A400,
      contrastText: '#fff'
    },
    background: {
      default: '#fff',
      // inner: '#fff'
      inner: '#f9f9f9'
    },
    text: {
      primary: '#333',
      secondary: '#666',
      disabled: '#777',
      hint: '#777'
    }
  }
})

theme.overrides = {
  MuiPickersDay: {
    day: {
      color: theme.palette.secondary.main,
      fontWeight:'bold',
    },
    daySelected: {
      backgroundColor:theme.palette.primary.main,
    },
    dayDisabled: {
      color: '#ddd',
      fontWeight:'regular',
    },
    current: {
      color: theme.palette.primary.main,
    },
  },
  MuiContainer: {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    }
  },
  MuiPaper: {
    rounded: {
      borderRadius: 8
    }
  },
  MuiAvatar: {
    colorDefault: {
      backgroundColor: '#ececec'
    },
    rounded: {
      borderRadius: 8
    }
  },
  MuiButton: {
    root: {
      padding: theme.spacing(1, 2)
    }
  },
  MuiOutlinedInput: {
    input: {
      padding: theme.spacing(2,2)
    }
  },
  MuiFormHelperText: {
    contained: {
      marginLeft: 0,
    }
  },
  MuiFormControlLabel: {
    root: {
      marginRight: 0
    }
  }
}

export default theme;
import Model from './lib/Model';

class Customer extends Model {

  constructor(raw) {
    super(raw);
    this.name = raw.name;
    this.businessNumber = raw.businessNumber;
    this.agent = raw.agent;
    this.email = raw.email;
    this.tel = raw.tel;
    this.ownerName = raw.ownerName;
    this.postalCode = raw.postalCode;
    this.address1 = raw.address1;
    this.address2 = raw.address2;
    this.accountBank = raw.accountBank;
    this.accountNumber = raw.accountNumber;
    this.accountHolder = raw.accountHolder;
    this.granted = raw.granted;
  }
}

export default Customer;

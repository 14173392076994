import React from 'react'
import { makeStyles, Box, Container } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  layout: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    flexGrow: 1,
    flexShrink: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0
  }
}))

const PlainLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.layout}>
      <Container className={classes.container} maxWidth={'lg'}>
        {children}
      </Container>
    </Box>
  )
}

export default PlainLayout;
import React from 'react'

const Logo = (props) => {
  return <img
    alt={'snecompany'}
    src={'./logo.png'}
    height={'48'}
    {...props}
  />
}

export default Logo;